import Vue from "vue";

export default class CategoriesService {
  static baseUrl = "category";

  static create(payload = {}) {
    return Vue.axios.post(this.baseUrl, payload);
  }

  static show(id) {
    return Vue.axios.get(`${this.baseUrl}/${id}`);
  }

  static update(payload = {}, id) {
    return Vue.axios.put(`${this.baseUrl}/${id}`, payload);
  }

  static changeStatus(status, id) {
    return Vue.axios.put(`${this.baseUrl}/${id}/change-status`, { status });
  }

  static searchCategories(search = "", onlyParent = false) {
    let uri = `${this.baseUrl}/search-select?only_parent=${onlyParent}`;

    if (search) {
      uri += `&search=${search}`;
    }

    return Vue.axios.get(uri);
  }
}
