<template>
  <div>
    <base-header type="gradient-warning" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row class="justify-content-center">
        <b-col md="6">
          <card>
            <VeeObserver ref="form" slim>
              <VeeProvider name="title" rules="required" v-slot="{ errors }">
                <base-input
                  class="input-group-alternative"
                  placeholder="Title"
                  v-model="payload.title"
                  :error="errors[0]"
                />
              </VeeProvider>
              <b-form-group label="Menu type" v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="urlMode"
                  :aria-describedby="ariaDescribedby"
                  name="url_mode"
                  :value="true"
                >
                  Specific URL
                </b-form-radio>
                <b-form-radio
                  v-model="urlMode"
                  :aria-describedby="ariaDescribedby"
                  name="url_mode"
                  :value="false"
                >
                  Category group
                </b-form-radio>
              </b-form-group>
              <VeeProvider name="url" rules="required|url" v-slot="{ errors }" v-if="urlMode">
                <base-input
                  class="input-group-alternative"
                  placeholder="Url (Ex: http://google.com)"
                  addon-left-icon="fa fa-globe"
                  v-model="payload.url"
                  :error="errors[0]"
                />
              </VeeProvider>
              <VeeProvider name="categories" rules="required" v-slot="{ errors }" v-else>
                <b-form-group label="Select the categories">
                  <v-select
                    @search="searchCategories"
                    multiple
                    :reduce="({ code }) => code"
                    v-model="payload.categories"
                    :options="selectCategories"
                    placeholder="Select"
                  />
                  <small class="text-danger" v-if="errors.length">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </VeeProvider>
              <VeeProvider name="image" rules="required|length:1" v-slot="{ errors }">
                <b-form-group label="Image(Trademark)">
                  <FileUpload v-model="payload.image" ref="image" />
                  <small class="text-danger" v-if="errors.length">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </VeeProvider>
              <div class="text-center">
                <base-button
                  variant="primary"
                  type="submit"
                  class="my-4"
                  @click="isEdit ? update() : create()"
                  :loading="loading"
                >
                  <i class="fa fa-save"></i> {{ isEdit ? "Update" : "Create" }}
                </base-button>
              </div>
            </VeeObserver>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import MenusService from "features/menus/services/MenusService";
import CategoriesService from "features/categories/services/CategoriesService";

import { resource } from "helpers";

export default {
  name: "FormMenus",
  data: () => ({
    loading: false,
    urlMode: true,
    payload: {
      title: "",
      url: "",
      image: [],
      categories: [],
    },
    selectCategories: [],
  }),
  computed: {
    isEdit() {
      return this.$route.name === "menus-edit";
    },
  },
  mounted() {
    if (this.isEdit) this.getData();
    this.searchCategories("");
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await MenusService.show(this.$route.params.id);
        this.payload.title = data.title;
        this.payload.image = [resource(data.image)];
        if (data?.categories?.length > 0) {
          const dataCategories = [];
          data.categories.forEach((value) => {
            dataCategories.push(value.id);
          });
          this.payload.categories = dataCategories;
          this.urlMode = false;
        } else {
          this.payload.url = data.url;
          this.urlMode = true;
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async create() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { title, url, image, categories } = this.payload;
          const payload = {
            title,
            image: {
              type: image[0].ext,
              value: image[0].item,
            },
          };
          if (this.urlMode) {
            payload.url = url;
          } else {
            payload.categories = categories;
          }
          await MenusService.create(payload);
          this.$swal({
            icon: "success",
            title: "Registration performed successfully",
          }).then(() => this.$router.push("/menus"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Trademark registration failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { title, image, url, categories } = this.payload;
          const payload = { title };
          if (image[0].constructor.name === "Object") {
            payload.image = {
              type: image[0].ext,
              value: image[0].item,
            };
          }
          if (this.urlMode) {
            payload.url = url;
          } else {
            payload.categories = categories;
          }
          await MenusService.update(payload, this.$route.params.id);
          this.$swal({
            icon: "success",
            title: "Record updated successfully",
          }).then(() => this.$router.push("/menus"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async searchCategories(value = "") {
      try {
        const { data } = await CategoriesService.searchCategories(value, true);
        this.selectCategories = data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
